import * as types from './bonds.types'

const requestBonds = () => ({
  type: types.BONDS_REQUEST,
  payload: {
    isCached: false,
    isFetching: true,
  },
})

const receiveBonds = (bondsPageOne, bondsPageTwo) => ({
  type: types.BONDS_RECEIVED,
  payload: {
    bonds: [...bondsPageOne, ...bondsPageTwo],
    isCached: true,
    isFetching: false,
  },
})

const bondsError = error => ({
  type: types.BONDS_ERROR,
  payload: {
    bonds: null,
    error,
    isCached: false,
    isFetching: false,
  },
})

const fetchBonds = () => async (dispatch) => {
  dispatch(requestBonds())

  const bondPageOne = await fetch(`https://bonds.conv-ex.com/cms/wp-json/wp/v2/bonds?per_page=100`)
    .then(function(response) {
      return response.json()
    })

  const bondPageTwo = await fetch(`https://bonds.conv-ex.com/cms/wp-json/wp/v2/bonds?per_page=100&page=2`)
    .then(function(response) {
      return response.json()
    })

  const bondPageThree = await fetch(`https://bonds.conv-ex.com/cms/wp-json/wp/v2/bonds?per_page=100&page=3`)
    .then(function(response) {
      return response.json()
    })

  dispatch(receiveBonds(bondPageOne, bondPageTwo, bondPageThree))
}


export const fetchBondsIfNeeded = () => (dispatch, getState) => {
  const { bonds } = getState()

  if (!bonds.isCached) {
    return dispatch(fetchBonds())
  }
}

export {
  requestBonds,
  receiveBonds,
  bondsError,
}
