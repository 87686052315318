import { connect } from 'react-redux'
import { userSignin } from '../redux/Auth/auth.actions'
import LoginPage from '../screens/Login/Login'

const mapStateToProps = (state) => {
  return {
    bonds: state.bonds.bonds
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    userSignin: (user) => { dispatch(userSignin(user)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)