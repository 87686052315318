import { connect } from 'react-redux'

import { fetchBondsIfNeeded } from '../redux/Bonds/bonds.actions'
import Adjustments from '../screens/Adjustments/Adjustments'

const mapStateToProps = (state) => {
  return {
    bonds: state.bonds.bonds
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBondsIfNeeded: () => { dispatch(fetchBondsIfNeeded()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Adjustments)