export const returnFormatted = (value) => {
  if(Array.isArray(value)) {
    let arrayFormatted = value.join()
    return (
      <span style={{textTransform: 'uppercase'}}>
        {arrayFormatted}
      </span>
    )
  }
  else if(value === null || value === "" || value === undefined) {
    return <span style={{textTransform: 'lowercase'}}>{'n/a'}</span>
  }
  else if(value === false) {
    return <span style={{textTransform: 'capitalize'}}>{'No'}</span>
  }
  else if(value === true) {
    return <span style={{textTransform: 'capitalize'}}>{'Yes'}</span>
  }
  else {
    let strippedValue = value.replace(/-/g," ")
    strippedValue = value.replace(/_/g," ")
    return <span style={{textTransform: 'capitalize', display: 'inline-block'}}>{strippedValue}</span>
  }
}

export const returnUppercase = (value) => {
  return <span style={{textTransform: 'uppercase', display: 'inline-block'}}>{value}</span>
}

export const returnCapitalised = (value) => {
  return <span style={{textTransform: 'capitalize', display: 'inline-block'}}>{value}</span>
}

export const returnFormattedNumber = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}