import React from "react"
import PropTypes from "prop-types"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"
import SingleBondSummary from "../SingleBondSummary/SingleBondSummary"
import SingleBondAdjustments from "../SingleBondAdjustments/SingleBondAdjustments"
import SingleBondIssuance from "../SingleBondIssuance/SingleBondIssuance"
import SingleBondNotices from "../SingleBondNotices/SingleBondNotices"

const TabLayout = ({ bondDetails }) => {
  return (
    <Tabs>
      <TabList>
        <Tab>Overview</Tab>
        <Tab>Issuance Documentation</Tab>
        <Tab>Adjustment History</Tab>
        <Tab>Adjustment Notices</Tab>
      </TabList>
  
      <TabPanel>
        <SingleBondSummary bondDetails={bondDetails} />
      </TabPanel>

      <TabPanel>
        {bondDetails.issuance_documentation && 
          <SingleBondIssuance bondIssuances={bondDetails.issuance_documentation} />
        }
      </TabPanel>

      <TabPanel>
        {bondDetails.adjustment_history && 
          <SingleBondAdjustments bondAdjustments={bondDetails.adjustment_history} />
        }
      </TabPanel>

      <TabPanel>
        {bondDetails.adjustment_notices && 
          <SingleBondNotices bondNotices={bondDetails.adjustment_notices} />
        }
      </TabPanel>

    </Tabs>
  )
}

TabLayout.propTypes = {
  bondDetails: PropTypes.object,
}

export default TabLayout