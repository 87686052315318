import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import Layout from "../../elements/Layout/Layout";
import { FormWrapper, LargeParagraph, SmallParagraph } from "./styles";

const LoginPage = (props) => {
  const { userSignin } = props;

  const [isSubmitting, setIsSubmitting] = useState(false)
  
  return (
    <Layout noMenu elementClass={"padding-large"}>
      <LargeParagraph>Login to ConvEx information service</LargeParagraph>

      <FormWrapper>
        <Formik
          initialValues={{
            username: "",
            password: "",
          }}
          onSubmit={async (values) => {
            setIsSubmitting(true)
            userSignin(values)
          }}
        >
          <Form>
            <div className="form-group">
              <label htmlFor="username">Username</label>
              <Field type="text" name="username" placeholder="Username" required />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <Field type="password" name="password" placeholder="Password" required />
            </div>
            <button type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Submitting" : "Submit"}
            </button>
          </Form>
        </Formik>
      </FormWrapper>

      <SmallParagraph>
        Having trouble logging in or want to request access?
      </SmallParagraph>

      <SmallParagraph>
        <a href="mailto: info@conv-ex.com" target="_blank" rel="noreferrer">
          Contact ConvEx
        </a>
      </SmallParagraph>
    </Layout>
  );
};

export default LoginPage;
