import React, { useEffect, useState} from "react"
import { withRouter } from "react-router-dom"
import Layout from "../../elements/Layout/Layout"
import Spinner from "../../elements/Spinner/Spinner"
import ReactPdfViewer from "../../components/ReactPdfViewer/ReactPdfViewer"
import { BackButton } from "./styles"

const Viewer = ({ history }) => {

  const [fileUrl, setFileUrl] = useState(null)

  useEffect(() => {
    const viewerUrl = localStorage.getItem("viewerUrl")
    setFileUrl(viewerUrl)
  }, [])
  
  return (
    <div style={{background: "#e5e5e5"}}>
      {fileUrl ?
        <Layout>
          <BackButton onClick={history.goBack}>Go back</BackButton>
          <ReactPdfViewer fileUrl={fileUrl} />
        </Layout>
      :
        <Spinner />
      }
    </div>
  )
}

export default withRouter(Viewer)