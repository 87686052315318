import * as types from './bonds.types'

const defaultState = {
  isCached: false,
  isFetching: false,
  bonds: null,
  error: '',
}

const bondReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case types.BONDS_ERROR:
    case types.BONDS_REQUEST:
    case types.BONDS_RECEIVED:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default bondReducer
