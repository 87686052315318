import styled from "styled-components"

const FilterWrapper = styled.section`
  border-top: 1px solid #cacaca;
  border-bottom: 1px solid #cacaca;
  font-size: 14px;
  height: 3.5rem;
  margin: 0 auto;
  overflow: visible;
  width: 100%;
  svg { 
    color: black;
  }
  
  @media only screen and (max-width: 990px) {
    display: none;
  }
`

const FilterRow = styled.div`
  overflow: visible;
`

const SelectWrapper = styled.div`
  overflow: visible;
  float: left;
  padding: .5rem 0;
  width: 70%;
  > div { 
    float: left;
    > div {
      border-radius: 0;
      margin-right: 5px;
    }
  }
  @media screen and (min-width: 990px) {
    > div {
      margin-bottom: 0;
      width: ${props => props.thin ? "25%" : "20%"};
    }
  } 
`

const SearchWrapper = styled.div`
  float: left;
  padding: .5rem 0;
  width: 15%;
  input { 
    height: 38px;
    border-radius: 50px;
    border: solid 1px #c6cbd4;
    padding: 0 1rem;
    width: 100%;
    &::placeholder {
      font-size: 14px;
    }
  }
`

const Pagination = styled.div`
  float: right;
  padding: .5rem 0;
  span.cb-pagination {
    display: block;
    float: right;
    height: 2rem;
    padding: .75rem;
  }
  div {
    float: right;
  }
  @media screen and (min-width: 768px) {
    display: block;
    width: 15%;
  } 
  @media (min-width:990px) and (max-width: 1350px) {
    span.cb-pagination-detail {
      display: none;
    }
  }
`

const PaginationButton = styled.button`
  background: transparent;
  color: #c6cdb4;
  border: 1px solid #c6cbd4;
  border-radius: 0;
  height: 2.5rem;
  box-shadow: none;
  float: right;
`

// Single bonds

const BondSection = styled.section`
  padding: 1.5rem 0;
`

const BondHeading = styled.h2`
  margin-bottom: 2.5rem;
  text-align: center;
`

const BondTable = styled.table`
  font-size: 14px;
  width: 100%;

  &.table-narrow {
    @media screen and (min-width: 768px) {
      width: 80%;
    }
  }

  th, td {
    line-height: 1.2; 
  }

  th {
    padding: .75rem 10px;
  }

  tr {
    td {
      @media screen and (min-width: 768px) {
        padding: 5px 10px;
      }
    }
    &:first-child {
      td {
        padding-top: 15px;
      }
    }
  }
  
  .td-button {
    a {
      float: right;
      max-width: 6rem;
    }
  }


  button {
    float: right;
    font-size: 13px;
    padding: 5px 1rem;
    line-height: 24px;
  }

  span {
    display: inline-block;
  }
  
  @media only screen and (max-width: 768px) {
    
    /* Force table to not be like tables anymore */
    thead, 
    tbody, 
    th, 
    td, 
    tr { 
      display: block; 
    }
    
    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr { 
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr:nth-child(even) {
      background-color: rgba(198, 203, 212, 0.24);
    }
  
    tr {
      padding: 1rem 0;
    }
    
    td { 
      /* Behave  like a "row" */
      border: none;
      position: relative;
      padding: 10px;
      padding-left: 50%; 
      white-space: normal;
      text-align:left;
    }
   
    td:before { 
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 6px;
      left: 6px;
      width: 45%; 
      opacity: 0.5;
      padding: 0 20px; 
      top: 10px;
      white-space: nowrap;
      text-align:left;
    }
   
    .td-button {
      padding-left: 10px;
      button {
        float: none;
        max-width: 100%;
        width: 100%;
      }
    }

    .cell-pdf-icon img {
      display: none
    }
    
    /*
    Label the data
    */
    td:before { content: attr(data-title); }
    
`

export { 
  FilterWrapper, 
  FilterRow,
  SelectWrapper, 
  SearchWrapper,
  Pagination, 
  PaginationButton,
  BondHeading,
  BondSection,
  BondTable
}