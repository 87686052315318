import styled from 'styled-components'
import SearchIcon from "../../constants/images/search.svg";

export const SearchWrapper = styled.div`
  display: none;
  position: relative;
  @media screen and (min-width: 768px) {
    display: block;
  }
}
`

export const InputWrapper = styled.div`
  float: right;
  position: relative;
  transition: width .5s;
  input {
    background: #000;
    color: #fff;
    border: 1px solid #D8D8D8;
    border-radius: 50px;
    float: right;
    padding: .5rem;
    padding-left: 2rem;
    width: 100%;
    &:before {
      background-image: url(${SearchIcon})
    }
    &::-webkit-input-placeholder {
      color: #cacaca;
    }
  }
  &.inactive {
    width: 40px;
    input {
      &::-webkit-input-placeholder {
        opacity: 0;
      }
    }
  }
  &.active {
    width: 100%;
    input {
      &::-webkit-input-placeholder {
        opacity: 1;
      }
    }
  }
  img {
    height: 40px;
    left: 0;
    position: absolute;
    width: 40px;
  }
`
export const ResultsWrapper = styled.div`
  background: #fff;
  padding: 1.5rem;
  position: absolute;
  top: 45px;
  width: 100%;
  z-index: 10;
`

export const ResultsSection = styled.div`
  h2 {
    font-size: 11px;
    color: #333333;
  }
  table {
    width: 100%;
    th {
      font-size: 11px;
      color: #333333;
      opacity: 0.5;
    }
    tr {
      cursor: pointer;
      height: 30px;
    }
  }
`