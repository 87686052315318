import React from "react"
import Layout from "../../elements/Layout/Layout"

const NotFound = () => (
  <Layout elementClass={"padding-large"}>
    <h1>Page not found</h1>
    <p>This page cannot be found. Use the menu on the left to navigate, or 
      <a href="/">click here to return home.</a>
    </p>
  </Layout>
)

export default NotFound 