import styled from 'styled-components'

const Header = styled.header`
  background-color: #000;
  position: relative;
`

const HeaderLink = styled.a`
  color: white;
  text-decoration: none;
`

const HeaderTitle = styled.div`
  vertical-align: middle;
  a {
    align-items: center;
    display: flex;
    float: left;
    justify-content: space-between;
    line-height: 2;
    padding-left: 1rem;
    margin-right: 10px;
  }
`

const HeaderTagline = styled.p`
  color: white;
  display: none;
  font-size: 0.875rem;
  line-height: 1.75;
  margin-bottom: 0;
  @media screen and (min-width: 1300px) {
    display: inline-block;
  }
`

const InnerWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 6rem;
  margin: 0 auto;
  max-width: 1300px;
  padding: 0 10px;
  position: relative;
  width: 100%;
`

const FlexWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;
  > div {
    flex: 1;
  }
`

export { 
  Header, 
  HeaderLink,
  HeaderTitle,
  HeaderTagline,
  InnerWrapper,
  FlexWrapper,
}