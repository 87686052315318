import styled from 'styled-components'

const SiteFooter = styled.footer`
  background-color: black;
  color: white;
  font-size: 12px;
  padding: 2rem 0;
  @media (min-width: 768px) {
    padding: 3rem 0;
    min-height: 10rem;
  }
`

const InnerWrapper = styled.div`
  margin: 0 auto;
  max-width: 1220px;
  padding-top: 0;
  padding: 0 1rem;
  @media (min-width: 1250px) {
    padding: 0;
  }
`

const FooterLeft = styled.div`
  width: 100%;
  a { 
    color: white;
  }
  @media (min-width: 768px) {
    float: left;
    width: 50%;
  }
`

const FooterRight = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    float: left;
    text-align: right;
    width: 50%;
  }
`

export {
  FooterLeft, 
  FooterRight,
  SiteFooter,
  InnerWrapper,
}