export const USER_SIGNIN_REQUEST = 'USER_SIGNIN_REQUEST'
export const USER_SIGNIN_SUCCESS = 'USER_SIGNIN_SUCCESS'
export const USER_SIGNIN_ERROR = 'USER_SIGNIN_ERROR'

export const USER_SIGNOUT_REQUEST = 'USER_SIGNOUT_REQUEST'
export const USER_SIGNOUT_SUCCESS = 'USER_SIGNOUT_SUCCESS'
export const USER_SIGNOUT_ERROR = 'USER_SIGNOUT_ERROR'

export const VALIDATE_TOKEN_SUCCESS = 'VALIDATE_TOKEN_SUCCESS'
export const VALIDATE_TOKEN_FAIL = 'VALIDATE_TOKEN_FAIL'
