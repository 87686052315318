import styled from 'styled-components'

const TableWrapper = styled.section`
  overflow: visible;
  @media screen and (min-width: 990px) {
    padding: .5rem;
  }
`

export { 
  TableWrapper,
}