import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Select from "react-select";
import { useTable, useSortBy, usePagination, useFilters } from "react-table";

import { makeData, getColumns } from "../../utilities/makeData";

import {
  returnCapitalised,
  returnFormatted,
  returnUppercase,
  returnFormattedNumber,
} from "../../utilities/format";

import CaretLeft from "../../constants/images/caret-left.png";
import CaretRight from "../../constants/images/caret-right.png";

import {
  FilterWrapper,
  FilterRow,
  SelectWrapper,
  SearchWrapper,
  Pagination,
  PaginationButton,
} from "../../constants/styles/styles";
import { withRouter } from "react-router-dom";
import {
  countries,
  currencies,
  issueTypes,
  regions,
} from "../../utilities/stringMapping";

function Table({
  bonds,
  history,
  columns,
  data,
  selectedRegion,
  selectedCurrency,
  selectedInstrumentOption,
  selectedIssuerCountry,
  selectedUnderlyingCountry,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // Pagination
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex },
    // Filtering
    setFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        sortBy: [
          {
            id: "issuer_name",
            desc: false,
          },
        ],
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const regionList = [
    ...new Set(bonds.map((item) => item.acf.region && item.acf.region[0])),
  ];
  const regionOptions = regionList.map((region, i) => {
    return {
      value: region,
      label: regions[region] || returnCapitalised(region),
    };
  });

  const currencyList = [
    ...new Set(bonds.map((item) => item.acf.currency)),
  ].sort();
  const currencyOptions = currencyList.map((currency, i) => {
    return {
      value: currency,
      label: currencies[currency] || returnUppercase(currency),
    };
  });

  const instrumentList = [
    ...new Set(bonds.map((item) => item.acf.instrument_type)),
  ].sort();
  const instrumentOptions = instrumentList.map((instrument, i) => {
    return {
      value: instrument,
      label: issueTypes[instrument] || returnCapitalised(instrument),
    };
  });

  const issuerCountryList = [
    ...new Set(bonds.map((item) => item.acf.issuer_country)),
  ].sort();
  const issuerCountryOptions = issuerCountryList.map((issuerCountry, i) => {
    return {
      value: issuerCountry,
      label: countries[issuerCountry] || returnCapitalised(issuerCountry),
    };
  });

  const underlyingCountryList = [
    ...new Set(bonds.map((item) => item.acf.underlying_country)),
  ].sort();
  const underlyingCountryOptions = underlyingCountryList.map(
    (underlyingCountry, i) => {
      return {
        value: underlyingCountry,
        label:
          countries[underlyingCountry] || returnCapitalised(underlyingCountry),
      };
    }
  );

  const allOption = {
    label: "All",
    value: "",
  };

  const handleRegionChange = (e) => {
    const value = e.value || undefined;
    setFilter("region", value);
  };

  const handleCurrencyChange = (e) => {
    const value = e.value || undefined;
    setFilter("currency", value);
  };

  const handleInstrumentChange = (e) => {
    const value = e.value || undefined;
    setFilter("instrument_type", value);
  };

  const handleIssuerChange = (e) => {
    const value = e.value || undefined;
    setFilter("issuer_country", value);
  };

  const handleUnderlyingChange = (e) => {
    const value = e.value || undefined;
    setFilter("underlying_country", value);
  };

  // Logic for filtering
  const [filterInput, setFilterInput] = useState("");

  const handleSearchChange = (e) => {
    const value = e.target.value || undefined;
    setFilter("issuer_name", value);
    setFilterInput(value);
  };

  return (
    <>
      <FilterWrapper>
        <FilterRow>
          <SelectWrapper>
            <Select
              placeholder={"Region"}
              value={selectedRegion}
              onChange={handleRegionChange}
              options={[allOption, ...regionOptions]}
            />
            <Select
              placeholder={"Currency"}
              value={selectedCurrency}
              onChange={handleCurrencyChange}
              options={[allOption, ...currencyOptions]}
            />
            <Select
              placeholder={"Instrument Types"}
              value={selectedInstrumentOption}
              onChange={handleInstrumentChange}
              options={[allOption, ...instrumentOptions]}
            />
            <Select
              placeholder={"Issuer Country"}
              value={selectedIssuerCountry}
              onChange={handleIssuerChange}
              options={[allOption, ...issuerCountryOptions]}
            />
            <Select
              placeholder={"Underlying Country"}
              value={selectedUnderlyingCountry}
              onChange={handleUnderlyingChange}
              options={[allOption, ...underlyingCountryOptions]}
            />
          </SelectWrapper>
          <SearchWrapper>
            <input
              value={filterInput}
              onChange={handleSearchChange}
              placeholder={"Search"}
            />
          </SearchWrapper>
          <Pagination>
            <div>
              <PaginationButton
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                <img src={CaretRight} alt="" width="4" />
              </PaginationButton>{" "}
              <PaginationButton
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <img src={CaretLeft} alt="" width="4" />
              </PaginationButton>{" "}
            </div>
            <span className="cb-pagination">
              <span className="cb-pagination-detail">Page </span>
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
          </Pagination>
        </FilterRow>
      </FilterWrapper>

      <table
        id="convex-bond-matrix"
        {...getTableProps()}
        className="cb-table cb-table-alternative cb-table-hover"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? "cell-active top"
                        : "cell-active bottom"
                      : null
                  }
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={
                    column.Header === "Region" ||
                    column.Header === "Instrument Type"
                      ? { display: "none" }
                      : null
                  }
                >
                  <span className="th-span">{column.render("Header")}</span>
                  {column.isSorted && column.isSortedDesc ? (
                    <span className="chevron top"></span>
                  ) : (
                    <span className="chevron bottom"></span>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                onClick={() => {
                  history.push(`/convertible-bond/${row.original.id}`);
                }}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  if (cell.column.Header === "Issuer") {
                    return (
                      <td
                        data-title={cell.column.Header}
                        {...cell.getCellProps()}
                      >
                        {cell.row.original.issuer_name}
                      </td>
                    );
                  } else if (cell.column.Header === "Underlying Stock") {
                    return (
                      <td
                        data-title={cell.column.Header}
                        {...cell.getCellProps()}
                      >
                        {cell.row.original.underlying_stock}
                      </td>
                    );
                  } else if (cell.column.Header === "Currency") {
                    return (
                      <td
                        data-title={cell.column.Header}
                        {...cell.getCellProps()}
                      >
                        {returnUppercase(cell.value)}
                      </td>
                    );
                  } else if (cell.column.Header === "Issue Size") {
                    return (
                      <td
                        data-title={cell.column.Header}
                        {...cell.getCellProps()}
                      >
                        {returnFormattedNumber(cell.value)}
                      </td>
                    );
                  } else if (
                    cell.column.Header === "Issue Date" ||
                    cell.column.Header === "Maturity Date"
                  ) {
                    return (
                      <td
                        data-title={cell.column.Header}
                        {...cell.getCellProps()}
                      >
                        {moment.utc(cell.value).format("D-MMM-YYYY")}
                      </td>
                    );
                  } else if (
                    cell.column.Header === "Issuer Country" ||
                    cell.column.Header === "Underlying Country"
                  ) {
                    return (
                      <td
                        data-title={cell.column.Header}
                        {...cell.getCellProps()}
                      >
                        {countries[cell.value] || returnUppercase(cell.value)}
                      </td>
                    );
                  }
                  return (
                    <td
                      data-title={cell.column.Header}
                      {...cell.getCellProps()}
                      style={
                        cell.column.Header === "Region" ||
                        cell.column.Header === "Instrument Type"
                          ? { display: "none" }
                          : null
                      }
                    >
                      {returnFormatted(cell.value)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

const BondMatrix = withRouter(({ history, bonds }) => {
  const columns = getColumns();
  const data = makeData(bonds);
  return (
    <Table history={history} bonds={bonds} columns={columns} data={data} />
  );
});

BondMatrix.propTypes = {
  history: PropTypes.object,
  bonds: PropTypes.array,
};

export default BondMatrix;
