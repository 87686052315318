/* ------
// ALL BONDS
------- */
const makeRow = (bond) => {
  return {
    id: bond.id,
    region: bond.acf.region,
    issuer_name: bond.acf.issuer_name,
    underlying_stock: bond.acf.underlying_stock,
    maturity_date: bond.acf.maturity_date,
    issue_date: bond.acf.issue_date,
    coupon: bond.acf.coupon,
    currency: bond.acf.currency,
    issue_size: bond.acf.issue_size,
    isin: bond.acf.isin,
    issuer_country: bond.acf.issuer_country,
    underlying_country: bond.acf.underlying_country,
    instrument_type: bond.acf.instrument_type,
  }
}

const makeData = (bonds) => {
  return bonds.map(bond => {
    return {
      ...makeRow(bond)
    };
  });
}

const getColumns = () => {
  return ([
    {
      Header: "Region",
      accessor: "region",
    },
    {
      Header: "Issuer",
      id: "issuer_name",
      accessor: d => String(d.issuer_name.toLowerCase())
    },
    {
      Header: "Underlying Stock",
      id: "underlying_stock",
      accessor: d => String(d.underlying_stock.toLowerCase())
    },
    {
      Header: "Maturity Date",
      accessor: "maturity_date",
    },
    {
      Header: "Issue Date",
      accessor: "issue_date",
    },
    {
      Header: "Coupon",
      accessor: "coupon",
    },
    {
      Header: "Currency",
      accessor: "currency",
    },
    {
      Header: "Issue Size",
      id: "issue_size",
      accessor: d => parseFloat(d.issue_size.replace(/,/g, "")) 
    },
    {
      Header: "ISIN",
      accessor: "isin",
    },
    {
      Header: "Issuer Country",
      accessor: "issuer_country",
    },
    {
      Header: "Underlying Country",
      accessor: "underlying_country",
    },
    {
      Header: "Instrument Type",
      accessor: "instrument_type",
    },
  ])
}
/* ------
// ALL BONDS
------- */



/* ------
LATEST TRANSACTIONS 
------- */
const makeLatestRow = (transaction) => {
  return {
    id: transaction.id,
    issuer: transaction.acf.issuer_name, 
    underlying_stock: transaction.acf.underlying_stock,
    coupon: transaction.acf.coupon,
    maturity_date: transaction.acf.maturity_date,
    issue_date: transaction.acf.issue_date
  }
}

const makeLatestData = (transactions) => {
  return transactions.map(transaction => {
    return {
      ...makeLatestRow(transaction)
    };
  });
}


const getLatestColumns = () => {
  return ([
    {
      Header: "Issuer",
      accessor: "issuer",
    },
    {
      Header: "Underlying Stock",
      accessor: "underlying_stock",
    },
    {
      Header: "Coupon",
      accessor: "coupon",
    },
    {
      Header: "Maturity Date",
      accessor: "maturity_date",
    },
    {
      Header: "Issue Date",
      accessor: "issue_date",
    },
  ])
}
/* ------
END LATEST TRANSACTIONS 
------- */



/* ------
LATEST - ADJUSTMENT NOTICES
------- */
const makeNoticesRow = (notice) => {
  return {
    notice_date: notice.effective_date,
    issue: notice.title,
    document_type: notice.document_type,
    pdf_link: notice.file,
  }
}

function makeNoticesData(bonds) {
  let adjustmentArray = []
  bonds.map(bond => {
    if(bond.acf.adjustment_notices) {
      let title = bond.title
      return bond.acf.adjustment_notices.map(function(notice) {
        notice.title = title.rendered
        adjustmentArray.push(notice)
        return adjustmentArray
      })
    }
    return null
  })

  adjustmentArray.sort((a, b) => { return new Date(b.effective_date) - new Date(a.effective_date) } )
  return adjustmentArray.map(notice => {
    return {
      ...makeNoticesRow(notice)
    };
  });
}

const getNoticesColumns = () => {
  return ([
    {
      Header: "Notice Date",
      accessor: "notice_date",
    },
    {
      Header: "Issue",
      accessor: "issue",
    },
    {
      Header: "Document Type",
      accessor: "document_type",
    }
  ])
}

const getFullNoticesColumns = () => {
  return ([
    {
      Header: "Notice Date",
      accessor: "notice_date",
    },
    {
      Header: "Issue",
      accessor: "issue",
    },
    {
      Header: "Document Type",
      accessor: "document_type",
    },
    {
      Header: "Description",
      accessor: "description",
    },
    {
      Header: "",
      accessor: "pdf_link"
    }
  ])
}
/* ------
// LATEST - ADJUSTMENT NOTICES 
------- */




/* ------
LATEST - ISSUANCE DOCUMENTATION 
------- */

// const makeLatestRow = (issuance) => {
//   return {
//     document_date: issuance.document_date,
//     issue: issuance.title,
//     document_type: issuance.document_type,
//     description: issuance.description,
//     pdf_link: issuance.file,
//   }
// }

// function makeLatestData(bonds) {
//   let issuanceArray = []
//   bonds.map(bond => {
//     if(bond.acf.issuance_documentation) {
//       let title = bond.title
//       return bond.acf.issuance_documentation.map(function(notice) {
//         notice.title = title.rendered
//         issuanceArray.push(notice)
//         return issuanceArray
//       })
//     }
//     return null
//   })

//   issuanceArray.sort((a, b) => { return new Date(b.document_date) - new Date(a.document_date) } )
  
//   return issuanceArray.map(issuance => {
//     return {
//       ...makeLatestRow(issuance)
//     };
//   });
// }

// const getLatestColumns = () => {
//   return ([
//     {
//       Header: "Document Date",
//       accessor: "document_date",
//     },
//     {
//       Header: "Issue",
//       accessor: "issue",
//     },
//     {
//       Header: "Document Type",
//       accessor: "document_type",
//     },
//     {
//       Header: "Description",
//       accessor: "description",
//     },
//     {
//       Header: "File",
//       accessor: "pdf_link",
//     },
//   ])
// }

/* ------
LATEST - ISSUANCE DOCUMENTATION 
------- */

export { 
  makeData, 
  getColumns,
  makeLatestData, 
  getLatestColumns,
  makeNoticesData, 
  getNoticesColumns,
  getFullNoticesColumns,
}