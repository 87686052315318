import React, { useEffect } from "react"
import PropTypes from "prop-types"
import Layout from "../../elements/Layout/Layout"
import Spinner from "../../elements/Spinner/Spinner"
import AdjustmentMatrix from "../../components/AdjustmentMatrix/AdjustmentMatrix"

const Adjustments = ({ bonds, fetchBondsIfNeeded, history }) => {
  
  useEffect(() => {
    fetchBondsIfNeeded()
  })

  return (
    <Layout>
      {bonds ?
      <>
        <h1>Adjustment Notices</h1>
        <AdjustmentMatrix bonds={bonds} history={history} />

      </>
      : <Spinner />}
    </Layout>
  )

}

Adjustments.propTypes = {
  history: PropTypes.object,
}

export default Adjustments 