import styled from 'styled-components'

const BackButton = styled.a`
  cursor: pointer;
  color: #9C2729;
  display: block;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 1rem;
  text-decoration: none;
  @media screen and (min-width: 1220px) {
    position: absolute;
    left: 0;
    top: 0; 
  }
  &:hover, &:focus {
    color: black;
  }
`

const TitleWrapper = styled.header`
  position: relative;
`

const Title = styled.h1`
  text-align: center;
`

export { 
  BackButton,
  Title,
  TitleWrapper,
}