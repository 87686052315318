import React from 'react'
import {
  Router,
  Switch,
  Route,  
} from 'react-router-dom'
import LogOut from './containers/LogOut'
import PrivateRoute from './PrivateRoute'
import history from './history'

function App() {
  return (
    <Router history={history}>
      <Switch>
        <Route path='/logout' exact component={LogOut} />
        <Route path='/' exact component={PrivateRoute} />
        <Route path='/:param' component={PrivateRoute} />
      </Switch>
    </Router>
  );
}

export default App;
