import React from "react"
import moment from "moment"
import { 
  returnCapitalised,
  returnFormatted, 
  returnFormattedNumber,
  returnUppercase,
} from "../../utilities/format"
import { 
  BondGridColumn,
  BondTile,
  BondTopSection,
  Table,
} from "./styles"
import { cocProtection, countries, couponFrequencies, governingLaws, regions, underlyingSectors } from "../../utilities/stringMapping"

const SingleBondSummary = ({bondDetails}) => {
  
  return (
    <BondTopSection id="bond-overview">
      <BondGridColumn>
        <BondTile>
          <Table>
            <tbody>
              <tr>
                <th>Issuer</th>
                <td>{returnFormatted(bondDetails.issuer_name)}</td>
              </tr>
              <tr>
                <th>Issuer Country</th>
                <td>{countries[bondDetails.issuer_country] || returnCapitalised(bondDetails.issuer_country)}</td>
              </tr>
              <tr>
                <th>Guarantor</th>
                <td>{returnFormatted(bondDetails.guarantor_name)}</td>
              </tr>
              <tr>
                <th>Guarantor Country</th>
                <td>{countries[bondDetails.guarantor_country] || returnCapitalised(bondDetails.guarantor_country)}</td>
              </tr>
              <tr>
                <th>Instrument Type</th>
                <td>{returnFormatted(bondDetails.instrument_type)}</td>
              </tr>
              <tr>
                <th>Ranking</th>
                <td>{returnFormatted(bondDetails.ranking)}</td>
              </tr>
              <tr>
                <th>Secured</th>
                <td>{returnFormatted(bondDetails.secured)}</td>
              </tr>
              <tr>
                <th>Governing Law</th>
                <td>{governingLaws[bondDetails.governing_law] || returnCapitalised(bondDetails.governing_law)}</td>
              </tr>
              <tr>
                <th>ISIN</th>
                <td>{returnFormatted(bondDetails.isin)}</td>
              </tr>
              <tr>
                <th>Region</th>
                <td>{regions[bondDetails.region] || returnCapitalised(bondDetails.region)}</td>
              </tr>
            </tbody>
          </Table>
        </BondTile>
      </BondGridColumn>

      <BondGridColumn>
        <BondTile>
        <Table>
            <tbody>
              <tr>
                <th>Currency</th>
                <td>{returnUppercase(bondDetails.currency)}</td>
              </tr>
              <tr>
                <th>Issue Size</th>
                <td>{returnFormattedNumber(bondDetails.issue_size)}</td>
              </tr>
              <tr>
                <th>Bonds Outstanding</th>
                <td>{returnFormattedNumber(bondDetails.bonds_outstanding)}</td>
              </tr>
              <tr>
                <th>Denomination</th>
                <td>{returnFormattedNumber(bondDetails.denomination)}</td>
              </tr>
              <tr>
                <th>Underlying Stock</th>
                <td>{returnFormatted(bondDetails.underlying_stock)}</td>
              </tr>
              <tr>
                <th>Underlying BBG Ticker</th>
                <td>{returnUppercase(bondDetails.underlying_bbg_ticker)}</td>
              </tr>
              <tr>
                <th>Underlying Currency</th>
                <td>{returnUppercase(bondDetails.underlying_currency)}</td>
              </tr>
              <tr>
                <th>Underlying Country</th>
                <td>{countries[bondDetails.underlying_country] || returnCapitalised(bondDetails.underlying_country)}</td>
              </tr>
              <tr>
                <th>Underlying Sector</th>
                <td>{underlyingSectors[bondDetails.underlying_sector] || returnCapitalised(bondDetails.underlying_sector)}</td>
              </tr>
            </tbody>
          </Table>
        </BondTile>
      </BondGridColumn>
      
      <BondGridColumn>
        <BondTile>
        <Table>
            <tbody>
              <tr>
                <th>Maturity Date</th>
                <td>
                  {moment.utc(bondDetails.maturity_date).format("D-MMM-YYYY")}
                </td>
              </tr>
              <tr>
                <th>Issue Date</th>
                <td>
                  {moment.utc(bondDetails.issue_date).format("D-MMM-YYYY")}
                </td>
              </tr>
              <tr>
                <th>Issue Price</th>
                <td>{returnFormatted(bondDetails.issue_price)}</td>
              </tr>
              <tr>
                <th>Redemption Price</th>
                <td>{returnFormatted(bondDetails.redemption_price)}</td>
              </tr>
              <tr>
                <th>Coupon</th>
                <td>{returnFormatted(bondDetails.coupon)}</td>
              </tr>
              <tr>
                <th>Coupon Frequency</th>
                <td>{couponFrequencies[bondDetails.coupon_frequency] || returnCapitalised(bondDetails.coupon_frequency)}</td>
              </tr>
              <tr>
                <th>First Call Date</th>
                <td>{returnFormatted(bondDetails.first_call_date)}</td>
              </tr>
              <tr>
                <th>First Put Date</th>
                <td>{returnFormatted(bondDetails.first_put_date)}</td>
              </tr>
            </tbody>
          </Table>
        </BondTile>
      </BondGridColumn>
      
      <BondGridColumn>
        <BondTile>
        <Table>
            <tbody>
              <tr>
                <th>Initial Conversion Price</th>
                <td>{bondDetails.conversion_price}</td>
              </tr>
              <tr>
                <th>Initial Conversion Ratio</th>
                <td>{returnFormatted(bondDetails.conversion_ratio)}</td>
              </tr>
              <tr>
                <th>Contingent Conversion</th>
                <td>{returnFormatted(bondDetails.contingent_conversion)}</td>
              </tr>
              <tr>
                <th>Dividend Protection</th>
                <td>{returnFormatted(bondDetails.dividend_protection)}</td>
              </tr>
              <tr>
                <th>Dividend Threshold</th>
                <td>{returnFormatted(bondDetails.dividend_threshold)}</td>
              </tr>
              <tr>
                <th>CoC Protection</th>
                <td>{cocProtection[bondDetails.coc_protection] || returnCapitalised(bondDetails.coc_protection)}</td>
              </tr>
              <tr>
                <th>Cash Alternative</th>
                <td>{returnFormatted(bondDetails.cash_alternative)}</td>
              </tr>
              <tr>
                <th>Share Redemption</th>
                <td>{returnFormatted(bondDetails.share_redemption)}</td>
              </tr>
            </tbody>
          </Table>
        </BondTile>
      </BondGridColumn>
    </BondTopSection>
  )
}

export default SingleBondSummary