import React, { useEffect } from "react"
import Layout from "../../elements/Layout/Layout"

const LogoutPage = ({ history, userSignout }) => {
  
  useEffect(() => {
    userSignout()
    history.push("/login")
  })

  return (
    <Layout>
      <h1>Logout</h1>
    </Layout>
  )
}

export default LogoutPage 