import React from "react"
import { withRouter } from "react-router-dom"
import { 
  returnFormatted,
} from "../../utilities/format"
import { 
  BondSection,
  BondHeading,
  BondTable,
} from "../../constants/styles/styles"

import PDFIcon from "../../constants/images/pdf-icon.png"

const handleClick = (history, file) => {
  if(localStorage.getItem("viewerUrl")) {
    localStorage.removeItem("viewerUrl")
  }
  localStorage.setItem("viewerUrl", file)
  history.push("/viewer")
}

const SingleBondIssuance = withRouter(({ 
  history,
  bondIssuances,
}) => {
  if(bondIssuances) {
    return (
      <BondSection id="bond-issuances">
        <BondHeading>{"Issuance Documentation"}</BondHeading>
        <BondTable className="table-narrow">
          <thead>
            <tr>
              <th>Date</th>
              <th>Document Type</th>
              <th>Description</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {bondIssuances.map(function(issuance, index) {
              return (
                <tr style={{textTransform: "capitalize"}} key={index}>
                  <td
                    className="cell-pdf-icon"
                    data-title="Date"
                  >
                    <img src={PDFIcon} alt="" width="25" />
                    <span>{issuance.document_date}</span>
                  </td>
                  <td data-title="Document Type">{returnFormatted(issuance.document_type)}</td>
                  <td data-title="Description">{returnFormatted(issuance.description)}</td>
                  <td className="td-button">
                    <button onClick={function() {
                      if(issuance.file) {
                        handleClick(history, issuance.file)
                      }
                    }}>
                      View
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </BondTable>
      </BondSection>
    )
  }
  return (
    <BondSection id="bond-adjustments">
      <BondHeading>{"Issuance Documentation"}</BondHeading>
      <p className="center">None</p>
    </BondSection>
  )
})

export default SingleBondIssuance