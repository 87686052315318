import React from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { useTable, useSortBy, usePagination } from "react-table";
import { returnFormatted } from "../../utilities/format";
import { makeLatestData, getLatestColumns } from "../../utilities/makeData";

function Table({ history, columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // Pagination
    page,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
    },
    useSortBy,
    usePagination
  );

  return (
    <div className="section-latest-transactions">
      <h2>Latest Transactions</h2>

      <button
        className="button-all button-first"
        onClick={() => history.push("/convertible-bonds")}
      >
        View all Transactions
      </button>

      <table
        id="convex-latest-matrix"
        {...getTableProps()}
        className="cb-table cb-table-alternative cb-table-hover cb-table-latest"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  className={column.isSorted ? "cell-active" : null}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <span className="th-span">{column.render("Header")}</span>
                  {column.isSorted && column.isSortedDesc ? (
                    <span className="chevron top"></span>
                  ) : (
                    <span className="chevron bottom"></span>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={() => {
                  if (row.original.id) {
                    history.push(`/convertible-bond/${row.original.id}`);
                  }
                }}
              >
                {row.cells.map((cell) => {
                  if (
                    cell.column.Header === "Issue Date" ||
                    cell.column.Header === "Maturity Date"
                  ) {
                    return (
                      <td
                        data-title={cell.column.Header}
                        {...cell.getCellProps()}
                      >
                        {moment.utc(cell.value).format("D-MMM-YYYY")}
                      </td>
                    );
                  } else {
                    return (
                      <td
                        data-title={cell.column.Header}
                        {...cell.getCellProps()}
                      >
                        {returnFormatted(cell.value)}
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <button onClick={() => history.push("/convertible-bonds")}>
        View all Transactions
      </button>
    </div>
  );
}

const LatestTransactionMatrix = withRouter(({ history, bonds }) => {
  const columns = getLatestColumns();
  const data = makeLatestData(bonds);

  return <Table history={history} columns={columns} data={data} />;
});

export default LatestTransactionMatrix;
