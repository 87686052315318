import styled from 'styled-components'

const BondGridColumn = styled.div`
  display: flex;
  margin: .5rem 0;
  width: 100%;
  @media screen and (min-width: 768px) {
    float: left;
    padding-left: .5rem;
    padding-right: .5rem;
    width: 50%;
  }
  @media screen and (min-width: 1024px) {
    width: 25%;
  }
`

const BondTile = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.15);
  border: solid 1px #f6f9fc;
  background-color: #ffffff;
  flex: 1;
`

const BondTopSection = styled.section`
  overflow: auto;
  @media screen and (min-width: 768px) {
    display: flex;
  }
`

const Table = styled.table`
  font-size: 14px;
  tr {
    :first-child {
      th, td {
        padding-top: 2rem;
      }
    }
    :last-child {
      th, td {
        padding-bottom: 2rem;
      }
    }
  }
  th, td {
    line-height: 1.2:
    padding: .75rem 0;
    width: 50%;
  }
  th {
    padding: .5rem .5rem .5rem 1.5rem;
  }
  td {
    padding: .5rem 1.5rem .5rem .5rem;
    font-weight: bold;
  }
`
export { 
  BondGridColumn,
  BondTile,
  BondTopSection,
  Table,
}