import React from "react"
import { withRouter } from "react-router-dom"
import { 
  returnFormatted,
} from "../../utilities/format"
import { 
  BondSection,
  BondHeading,
  BondTable,
} from "../../constants/styles/styles"
import PDFIcon from "../../constants/images/pdf-icon.png"

const handleClick = (history, file) => {
  if(localStorage.getItem("viewerUrl")) {
    localStorage.removeItem("viewerUrl")
  }
  localStorage.setItem("viewerUrl", file)
  history.push("/viewer")
}

const SingleBondNotices = withRouter(({
  history,
  bondNotices,
}) => {
  if(bondNotices) {
    return (
      <BondSection id="bond-notices">
        <BondHeading>{"Adjustment Notices"}</BondHeading>
        <BondTable className="table-narrow">
          <thead>
            <tr>
              <th>Date</th>
              <th>Adjustment Type</th>
              <th>Description</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {bondNotices.map(function(notice, index) {
              return (
                <tr style={{textTransform: "capitalize"}} key={index}>
                  <td
                    className="cell-pdf-icon"
                    data-title="Date"
                  >
                    <img src={PDFIcon} alt="" width="25" />
                    <span>{notice.effective_date}</span>
                  </td>
                  <td data-title="Document Type">{returnFormatted(notice.document_type)}</td>
                  <td data-title="Description">{returnFormatted(notice.description)}</td>
                  <td className="td-button">
                    <button onClick={() => {
                      if(notice.file) {
                        handleClick(history, notice.file)
                      }
                    }}>
                      View
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </BondTable>
      </BondSection>
    )
  }
  return (
    <BondSection id="bond-adjustments">
      <BondHeading>{"Adjustment Notices"}</BondHeading>
      <p className="center">None</p>
    </BondSection>
  )
})

export default SingleBondNotices