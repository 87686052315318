import axios from '../../axios-wp'
import * as actionTypes from './auth.types'

export const userSignin = (user) => {
  return dispatch => {
    dispatch({type: 'USER_SIGNIN_REQUEST'})
    axios.post('/simple-jwt-authentication/v1/token', user)
      .then(response => {
        let userDets = {
          username: response.data.user_nicename,
          email: response.data.user_email,
          displayname:  response.data.user_display_name
        }
        //Set token and email in local storage in case Redux data is lost
        localStorage.setItem('token', response.data.token)
        localStorage.setItem('email', response.data.user_email)
        dispatch({type: 'USER_SIGNIN_SUCCESS', userDets: userDets, token: response.data.token})
      }).catch(err => {
        dispatch({type: 'USER_SIGNIN_FAIL', error: err})
      })
  }
}

export const userSignout = (token) => {
  return dispatch => {
    dispatch({type: 'USER_SIGNOUT_REQUEST'})
    axios.post('/simple-jwt-authentication/v1/token/revoke', {}, {
      headers: {'Authorization': 'Bearer ' + token}
    })
    .then(response => {
      //Clear local storage
      localStorage.removeItem('token')
      localStorage.removeItem('email')
      dispatch({type: actionTypes.USER_SIGNOUT_REQUEST})
    })
    .catch(err => {
      dispatch({
        type: actionTypes.USER_SIGNOUT_ERROR, 
        error: err
      })
    })
  }
}

export const validateToken = (url) => {
  return dispatch => {
    let token = null, email = null
    if(localStorage.getItem('token')){
      token = localStorage.getItem('token')
      email = localStorage.getItem('email')
    }
    if(token){
      axios.post('/simple-jwt-authentication/v1/token/validate', {}, { 
        headers: {'Authorization': 'Bearer ' + token}
      }).then(res => {
        if(res.data.data.status === 200){
          dispatch({type: actionTypes.VALIDATE_TOKEN_SUCCESS, token:token, email:email, redirectTo: url})
        }
      }).catch(err => {
        //TODO: HANDLE VALIDATION ERROR
      })
    } else{
      //TODO: HANDLE NO TOKEN
    }
  }
}