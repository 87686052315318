import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import Layout from "../../elements/Layout/Layout"
import Spinner from "../../elements/Spinner/Spinner"
import DesktopLayout from "../../components/DesktopLayout/DesktopLayout"
import TabLayout from "../../components/TabLayout/TabLayout"

import { 
  BackButton,
  Title,
  TitleWrapper,
} from "./styles"

const SingleBond = ({ match, history }) => {

  const [ isLoading, setIsLoading ] = useState(true)
  const [ bondDetails, setBondDetails ] = useState(null)

  // fetch bond from the CMS when parameter changes
  useEffect(() => {
    const fetchBond = async (id) => {
      setIsLoading(true)
      const bond = await fetch(`https://bonds.conv-ex.com/cms/wp-json/wp/v2/bonds/${id}`)
      .then(function(response) {
        return response.json()
      })
      setBondWhenLoaded(bond)
    }
    const { id } = match.params
    if(id) {
      fetchBond(id)
    }
  }, [match.params])

  // set bond details when finished loading
  const setBondWhenLoaded = (bond) => {
    setBondDetails(bond)
    setIsLoading(false)
  }

  const isMobile = (window.innerWidth < 768)

  return (
    <>
      <Layout>
        <TitleWrapper>
          <BackButton onClick={() => history.goBack()}>
            View all Bonds
          </BackButton>
          <Title>
            {bondDetails && !isLoading && bondDetails.title.rendered}
          </Title>
        </TitleWrapper>

        {!isLoading ?
          isMobile ? (
            <TabLayout 
              bondDetails={bondDetails.acf} 
            />
          ) : (
            <DesktopLayout
              bondDetails={bondDetails.acf} 
            />
          )
        : <Spinner />}
      </Layout>
    </>
  )
}

export default withRouter(SingleBond)