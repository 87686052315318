import React from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router"
import { Navigation, Burger, Button } from "./styles"

const MenuElement = withRouter(({ history, open }) => {
  return (
    <Navigation open={open}>
      <ul id="list-top">
        <li>
          <Button onClick={() => {history.push("/")}}>
            Latest Updates
          </Button>
        </li>
        <li>
          <Button onClick={() => {history.push("/convertible-bonds")}}>
            All Transactions
          </Button>
        </li>
        <li>
          <Button onClick={() => {history.push("/adjustment-notices")}}>
            All Adjustment Notices
          </Button>
        </li>
      </ul>
      <ul id="list-bottom">
        <li>
          <Button onClick={() => {history.push("/logout")}}>
            Log out
          </Button>
        </li>
        <li>
          <a href="http://conv-ex.com/" target="_blank" rel="noopener noreferrer">
            conv-ex.com
          </a>
        </li>
      </ul>
    </Navigation>
  )
})

const BurgerIcon = ({ open, setOpen }) => {
  return (
    <Burger open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </Burger>
  )
}

const Menu = () => {
  const [open, setOpen] = React.useState(false);
  const node = React.useRef();
  return (
      <div ref={node}>
        <BurgerIcon open={open} setOpen={setOpen} />
        <MenuElement open={open} setOpen={setOpen} />
      </div>
  )  
}

BurgerIcon.propTypes = {
  open: PropTypes.bool, 
  setOpen: PropTypes.func,
}

Menu.propTypes = {
  history: PropTypes.object, 
  open: PropTypes.bool, 
}

export default Menu