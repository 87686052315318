import { connect } from 'react-redux'
import { userSignout } from '../redux/Auth/auth.actions'
import LogoutPage from '../screens/Logout/Logout'

const mapStateToProps = (state) => {
  return {
    user: state.user,
    bonds: state.bonds.bonds
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    userSignout: (user) => { dispatch(userSignout(user)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoutPage)