import styled from 'styled-components'

const BackButton = styled.a`
  cursor: pointer;
  color: #9C2729;
  display: block;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 2rem;
  text-decoration: none;
  &:hover, &:focus {
    color: black;
  }
`

export { 
  BackButton,
}