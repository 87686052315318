import React from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import Menu from "../Menu/Menu"
import HeaderSearch from '../../containers/HeaderSearch'

import ConvexLogo from "./convex-logo.png"
import {
  Header, 
  HeaderLink,
  HeaderTitle,
  HeaderTagline,
  InnerWrapper,
  FlexWrapper,
} from "./styles"

const SiteHeader = withRouter(({ noMenu, history, bonds, user }) => (
  <Header>
    <InnerWrapper>
      { !noMenu && <Menu /> }
      <FlexWrapper>
        <HeaderTitle>
          <HeaderLink href="/">
            <img src={ConvexLogo} alt="ConvEx advisors logo" width="160" />
          </HeaderLink>
          <HeaderTagline>
            Independent Capital Markets Experts
          </HeaderTagline>
        </HeaderTitle>
        {/* {user.token && <HeaderSearch />} */}
        {bonds.bonds && <HeaderSearch history={history} />}
      </FlexWrapper>
    </InnerWrapper>
  </Header>
)) 

SiteHeader.propTypes = {
  bonds: PropTypes.array,
  history: PropTypes.object,
  siteTitle: PropTypes.string,
}

SiteHeader.defaultProps = {
  siteTitle: ``,
}

export default SiteHeader
