import styled from 'styled-components'

const MainElement = styled.main`
  padding: 2rem 0 3rem;
  overflow: auto;
  &.padding-large {
    padding: 7rem 0;
  }
`

const SiteWrapper = styled.div`
  margin: 0 auto;
  max-width: 1300px;
  padding-top: 0;
  padding: 0 1rem;
  position: relative;
  @media (min-width: 1250px) {
    padding: 0 10px;
  }
`

export { 
  MainElement,
  SiteWrapper,
}