import styled from 'styled-components'

const NavigationSection = styled.div`
  background: #fff;
  display: table;
  margin-bottom: 2rem;
  width: 100%;
  z-index: 10;
  div {
    cursor: pointer;
    background: #fff;
    border: 1px solid #C6CBD4;
    display: table-cell;
    text-align: center;
    &:hover, &:focus {
      background: #E8E8E8;
    }
    &.noClick {
      min-width: 5rem;
    }
    &.noClick, &.disabled { 
      cursor: default;
      &:hover, &:focus {
        background-color: #fff;
      }
      a {
        cursor: default;
      }
    }
    &.disabled {
      opacity: 0.5;
    }
    &.active {
      background: #E8E8E8;
    }
    a {
      background: #fff;
      color: #9C2729;
      display: block;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.5;
      padding: 1rem;
      text-decoration: none;
      &.active {
        background: #E8E8E8;
        color: #333;
      }
      &:hover, &:focus {
        background: #E8E8E8;
        color: #333;
      }
    }
    button {
      background: transparent;
      border: none;
    }
  }
  img { 
    height: 16px;
    width: auto;
  }
`

export { 
  NavigationSection,
}