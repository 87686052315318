import React from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { Link } from "react-scroll"
import SingleBondSummary from "../SingleBondSummary/SingleBondSummary"
import SingleBondAdjustments from "../SingleBondAdjustments/SingleBondAdjustments"
import SingleBondIssuance from "../SingleBondIssuance/SingleBondIssuance"
import SingleBondNotices from "../SingleBondNotices/SingleBondNotices"
import { NavigationSection } from "./styles"

const DesktopLayout = ({ bondDetails }) => (
  <>
    <NavigationSection id="singleBondNavigation">

      <div className="noClick">&nbsp;</div>

      <div>
        <Link
          activeClass="active"
          to="bond-overview"
          spy={true}
          smooth={true}
          offset={-700}
          duration={500}
        >
          Overview
        </Link>
      </div>

      <div>
        <Link
          activeClass="active"
          to="bond-issuances"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Issuance Documentation
        </Link>
      </div>

      <div>
        <Link
          activeClass="active"
          to="bond-adjustments"
          spy={true}
          smooth={true}
          offset={-200}
          duration={500}
        >
          Adjustment History
        </Link>
      </div>

      <div>
        <Link
          activeClass="active"
          to="bond-notices"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Adjustment Notices
        </Link>
      </div>

      <div className="noClick">&nbsp;</div>

    </NavigationSection>

    <SingleBondSummary bondDetails={bondDetails} />
    <SingleBondIssuance
      bondIssuances={bondDetails.issuance_documentation}
    />
    <SingleBondAdjustments
      bondAdjustments={bondDetails.adjustment_history}
    />
    <SingleBondNotices
      bondNotices={bondDetails.adjustment_notices}
    />
  </>
)

DesktopLayout.propTypes = {
  bondDetails: PropTypes.object,
}

export default withRouter(DesktopLayout)