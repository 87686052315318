import React from "react"
import PropTypes from "prop-types"

import SiteHeader from "../../containers/SiteHeader"

import Footer from "../Footer/Footer"
import "../../constants/styles/base.css"
import "../../constants/styles/tables.css"
import "../../constants/styles/tabs.css"
import {
  MainElement,
  SiteWrapper,
} from "./styles"

const Layout = ({ children, elementClass, noMenu = false, thin = false }) => {
  return (
    <>
      <SiteHeader noMenu={noMenu} siteTitle={"ConvEx Advisors"} />
      <SiteWrapper>
        <MainElement className={elementClass}>
          {children}
        </MainElement>
      </SiteWrapper>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  elementClass: PropTypes.string,
  thin: PropTypes.bool,
}

export default Layout
