import React, { useEffect } from "react"
import PropTypes from "prop-types"
import Layout from "../../elements/Layout/Layout"
import Spinner from "../../elements/Spinner/Spinner"
import LatestTransactionsMatrix from "../../components/LatestTransactionsMatrix/LatestTransactionsMatrix"
import LatestNoticesMatrix from "../../components/LatestNoticesMatrix/LatestNoticesMatrix"
import { TableWrapper } from "./styles"

const Home = ({ bonds, fetchBondsIfNeeded }) => {
  
  useEffect(() => {
    fetchBondsIfNeeded()
  })

  return (
    <Layout>
    {bonds ?
      <>
        <h1 className="centre">Latest Updates</h1>
        <TableWrapper>
          <LatestTransactionsMatrix bonds={bonds} />
          <LatestNoticesMatrix bonds={bonds} />
        </TableWrapper>
      </>
      : <Spinner />
    }
    </Layout>
  )
}

Home.propTypes = {
  bonds: PropTypes.array,
  fetchBondsIfNeeded: PropTypes.func,
}

export default Home 