import React, { useState } from "react"
import { withRouter } from "react-router-dom"
import Select from "react-select"
import { useTable, useFilters, useSortBy, usePagination } from "react-table"
import { returnFormatted, returnCapitalised } from "../../utilities/format"
import { makeNoticesData, getFullNoticesColumns } from "../../utilities/makeData"
import PDFIcon from "../../constants/images/pdf-icon.png"

import CaretLeft from "../../constants/images/caret-left.png"
import CaretRight from "../../constants/images/caret-right.png"
import { documentTypes } from "../../utilities/stringMapping"

import { 
  FilterWrapper,
  FilterRow,
  Pagination,
  PaginationButton,
  SearchWrapper,
  SelectWrapper,
} from "../../constants/styles/styles"

function Table({ 
  history,
  bonds,
  columns, 
  data,
  selectedType,
}) {
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // Pagination
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { 
      pageIndex,
    },
    setFilter,
  } = useTable({
      columns,
      data,
      initialState: { pageSize: 25 },
    },
    useFilters,
    useSortBy,
    usePagination,
  )

  const handleClick = (file) => {
    if (localStorage.getItem("viewerUrl")) {
      localStorage.removeItem("viewerUrl");
    }
    localStorage.setItem("viewerUrl", file);
    history.push("/viewer");
  }

  const allOption = {
    label: "All",
    value: ""
  }

  const typeList = bonds.reduce(function(result, item) {
    if (item.acf.adjustment_notices[0] && item.acf.adjustment_notices[0].document_type !== 'undefined') {
      result.push(item.acf.adjustment_notices[0].document_type)
    }
    return result;
  }, [])

  // sort and filter the reduced list from above
  const sortedTypeList = Array.from(new Set(typeList)).sort()
  
  // compile the array of objects
  const typeOptions = sortedTypeList.map((type, i) => {
    return { value: type, label: documentTypes[type] || returnCapitalised(type)}
  })

  const handleTypeChange = e => {
    const value = e.value || undefined;
    setFilter("document_type", value);
  }

  // Logic for filtering
  const [filterInput, setFilterInput] = useState("");

  const handleSearchChange = e => {
    const value = e.target.value || undefined;
    setFilter("issue", value);
    setFilterInput(value);
  };

    
  return (
    <>
      <FilterWrapper>
        <FilterRow>
          <SelectWrapper thin>
            <Select
              placeholder={"Document Type"}
              value={selectedType}
              onChange={handleTypeChange}
              options={[allOption, ...typeOptions]}
            />
          </SelectWrapper>
          <SearchWrapper>
            <input
              value={filterInput}
              onChange={handleSearchChange}
              placeholder={"Search"}
            />
          </SearchWrapper>
          <Pagination>
            <div>
              <PaginationButton onClick={() => nextPage()} disabled={!canNextPage}>
                <img src={CaretRight} alt="" width="4" />
              </PaginationButton>{" "}
              
              <PaginationButton onClick={() => previousPage()} disabled={!canPreviousPage}>
                <img src={CaretLeft} alt="" width="4" />
              </PaginationButton>{" "}
            </div>
            <span className="cb-pagination">
            <span className="cb-pagination-detail">Page{" "}</span>
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
          </Pagination>
        </FilterRow>
      </FilterWrapper>
      <table id="adjustment-matrix" {...getTableProps()} className="cb-table cb-table-narrow cb-table-nocursor">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th 
                  className={column.isSorted ? "cell-active" : null} 
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <span className="th-span">
                    {column.render("Header")}
                  </span>
                  {column.isSorted && column.isSortedDesc 
                    ? <span className="chevron top"></span>
                : <span className="chevron bottom"></span>
                  }
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                if(cell.column.id === "pdf_link") {
                  return (
                    <td
                      className="cb-pdf-file"
                      data-title={cell.column.Header}
                      {...cell.getCellProps()}
                    >
                      {cell.value ? (
                        <button
                          className="cb-button-pdf"
                          onClick={() => {
                            if (cell.value) {
                              handleClick(cell.value);
                            }
                          }}
                        >
                          View
                        </button>
                      ) : (
                        " "
                      )}
                    </td>
                  );
                } else if (cell.column.Header === "Notice Date") {
                  return (
                    <td
                      className="cell-pdf-icon"
                      data-title={cell.column.Header}
                      {...cell.getCellProps()}
                    >
                      <img src={PDFIcon} alt="" width="25" />
                      {returnFormatted(cell.value)}
                    </td>
                  );
                } else if (cell.column.Header === "Document Type") {
                  return (
                    <td
                      data-title={cell.column.Header}
                      {...cell.getCellProps()}
                    >
                      {documentTypes[cell.value] || returnCapitalised(cell.value)}
                    </td>
                  );
                } else {
                  return (
                    <td
                      data-title={cell.column.Header}
                      {...cell.getCellProps()}
                    >
                      {returnFormatted(cell.value)}
                    </td>
                  );
                }
              })}
            </tr>
            )}
          )}
        </tbody>
      </table>
      <FilterWrapper>
        <FilterRow>
          <Pagination>
            <div>
              <PaginationButton onClick={() => nextPage()} disabled={!canNextPage}>
                <img src={CaretRight} alt="" width="4" />
              </PaginationButton>{" "}
              
              <PaginationButton onClick={() => previousPage()} disabled={!canPreviousPage}>
                <img src={CaretLeft} alt="" width="4" />
              </PaginationButton>{" "}
            </div>
            <span className="cb-pagination">
            <span className="cb-pagination-detail">Page{" "}</span>
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
          </Pagination>
        </FilterRow>
      </FilterWrapper>
    </>
  );
}

const AdjustmentMatrix = withRouter(({ history, bonds }) => {
  const columns = getFullNoticesColumns();
  const data = makeNoticesData(bonds);

  return <Table history={history} bonds={bonds} columns={columns} data={data}  />
})

export default AdjustmentMatrix
