import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"
import moment from "moment"
import {
  SearchWrapper,
  InputWrapper,
  ResultsWrapper,
  ResultsSection,
} from "./HeaderSearch.styles"
import SearchIcon from "../../constants/images/search.svg"

const HeaderSearch = ({ bonds, history }) => {
  const [searchIsActive, setSearchIsActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState(null);
  const [isSearching, setIsSearching] = useState(false);

  const handleSearch = (e) => {
    clearTimeout();
    setTimeout(() => {
      setIsSearching(true);
      setSearchTerm(e.target.value);
    }, 500);
  };

  const toggleSearch = () => {
    setSearchIsActive(!searchIsActive);
  };

  return (
    <SearchWrapper>
      <Fragment>
        <InputWrapper className={searchIsActive ? "active" : "inactive"}>
          <img onClick={toggleSearch} src={SearchIcon} alt="Search" />
          <input
            autoComplete="off"
            type="text"
            name="header-search"
            placeholder="Search for transactions and notices"
            onChange={handleSearch}
          />
        </InputWrapper>
        {searchIsActive && isSearching && (
          <ResultsWrapper>
            <ResultsSection>
              <h2>Transactions</h2>
              <table>
                <thead>
                  <tr>
                  <th>Issue date</th>
                  <th>Issue</th>
                  <th>Maturity date</th>
                  </tr>
                </thead>
                <tbody>
                  {(bonds &&
                    searchTerm &&
                    bonds
                      .filter(
                        (bond) =>
                          bond.title.rendered
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase()) ||
                          bond.title.rendered
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                      )
                      .map((filteredBond) => (
                        <tr onClick={() => history.push(`/convertible-bond/${filteredBond.id}`)}>
                          <td>{moment.utc(filteredBond.acf.issue_date).format("D-MMM-YYYY")}</td>
                          <td>{filteredBond.title.rendered}</td>
                          <td>{moment.utc(filteredBond.acf.maturity_date).format("D-MMM-YYYY")}</td>
                        </tr>
                      ))) || <p>No results</p>}
                </tbody>
              </table>
            </ResultsSection>

            {/* <ResultsSection>
              <h2>Notices</h2>
              {(bonds &&
                searchTerm &&
                bonds
                  .filter(
                    (bond) =>
                      bond.title.rendered
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()) ||
                      bond.title.rendered
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                  )
                  .map((filteredBond) => (
                    <li>{filteredBond.title.rendered}</li>
                  ))) || <p>No results</p>}
            </ResultsSection> */}
          </ResultsWrapper>
        )}
      </Fragment>
    </SearchWrapper>
  )
}

HeaderSearch.propTypes = {
  history: PropTypes.object,
  bonds: PropTypes.array,
}
export default HeaderSearch;
