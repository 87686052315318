export const cocProtection = {
  "" : "n/a",
  "ratchet" : "Ratchet",
  "make-whole-table" : "Make-Whole Table",
  "k2-formula-bondholder" : "K2 Formula, Bondholder Put",
  "independent-expert-fair-value" : "Independent Expert Fair Value",
  "other-fair-value" : "Other Fair Value (See conditions for details)",
  "none" : "None",
}

export const countries = {
  "" : "n/a",
  "australia": "Australia", 
  "austria": "Austria", 
  "belgium": "Belgium",
  "canada": "Canada",
  "cayman_islands": "Cayman Islands",
  "denmark": "Denmark",
  "finland": "Finland",
  "france": "France",
  "germany": "Germany",
  "ireland": "Ireland",
  "italy": "Italy",
  "jersey": "Jersey",
  "luxembourg": "Luxembourg",
  "mexico": "Mexico",
  "netherlands": "Netherlands",
  "norway": "Norway",
  "russia": "Russia",
  "singapore": "Singapore",
  "south_africa": "South Africa",
  "spain": "Spain",
  "sweden": "Sweden",
  "switzerland": "Switzerland",
  "uae": "UAE",
  "uk": "UK",
  "usa": "USA"
}

export const couponFrequencies = {
  "" : "n/a",
  "n/a" : "n/a",
  "annual" : "Annual",
  "quarterly" : "Quarterly",
  "monthly" : "Monthly",
  "semi-annual" : "Semi-annual",
  "other" : "Other"
}

export const currencies = {
  "" : "n/a",
  aud: "AUD",
  eur: "EUR",
  gbp: "GBP",
  nok: "NOK",
  sek: "SEK", 
  usd: "USD",
  zar: "ZAR"
}

export const dividendAdjustmentTypes = {
  "" : "n/a",
  "one-way" : "One way",
  "two-way" : "Two way"
}

export const documentTypes = {
  "" : "n/a",
  "dividend": "Dividend",
  "other-adjustment": "Other adjustment",
  "pricing_termsheet": "Pricing Termsheet",
  "rights-issue-adjustment": "Rights Issue Adjustment",
  "spin-off-adjustment": "Spin Off Adjustment",
  "stock-split-adjustment": "Stock Split Adjustment",
  "t&cs": "Terms & Conditions",
}

export const governingLaws = {
  "" : "n/a",
  "austrian" : "Austrian",
  "belgian" : "Belgian",
  "dutch" : "Dutch",
  "english" : "English",
  "french" : "French",
  "german" : "German",
  "irish" : "Irish",
  "italian" : "Italian",
  "luxembourg" : "Luxembourg",
  "new-york" : "New York",
  "norwegian" : "Norwegian",
  "portugese" : "Portuguese",
  "south-african" : "South African",
  "spanish" : "Spanish",
}

export const issueTypes = {
  "" : "n/a",
  "bond-plus-warrant": "Bond plus warrant",
  "convertible": "Convertible",
  "exchangeable": "Exchangeable",
  "mandatory-convertible": "Mandatory Convertible",
  "mandatory-exchangeable": "Mandatory Exchangeable",
  "non-dilutive-convertible": "Non-dilutive Convertible"
}

export const regions = {
  "" : "n/a",
  "americas": "Americas",
  "emea": "EMEA"
}

export const underlyingSectors = {
  "" : "n/a",
  "communication-services" : "Communication Services",
  "consumer-discretionary" : "Consumer Discretionary",
  "consumer-staples" : "Consumer Staples",
  "energy" : "Energy",
  "financials" : "Financials",
  "health-care" : "Health Care",
  "industrials" : "Industrials",
  "information-technology" : "Information Technology",
  "materials" : "Materials",
  "real-estate" : "Real Estate",
  "utilities" : "Utilities",
}
