import React from "react"
import { 
  returnFormatted, 
} from "../../utilities/format"
import { 
  BondSection,
  BondHeading,
  BondTable,
} from "../../constants/styles/styles"

const SingleBondAdjustments = ({bondAdjustments}) => {
  if(bondAdjustments) {
    return (
      <BondSection id="bond-adjustments">
        <BondHeading>{"Adjustment History"}</BondHeading>
        <BondTable>
          <thead>
            <tr>
              <th>Effective Date</th>
              <th>Trigger</th>
              <th>Adjusted Conversion Price</th>
              <th>Adjusted Conversion Ratio</th>
              <th>Adjusted Dividend Threshold</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            {bondAdjustments.map(function(adjustment, index) {
              return (
                <tr style={{textTransform: "capitalize"}} key={index}>
                  <td data-title="Effective Date">{adjustment.effective_date}</td>
                  <td data-title="Trigger">{returnFormatted(adjustment.trigger)}</td>
                  <td data-title="Conversion Price">{adjustment.conversion_price}</td>
                  <td data-title="Conversion Ratio">{returnFormatted(adjustment.conversion_ratio)}</td>
                  <td data-title="Dividend Threshold">{returnFormatted(adjustment.dividend_threshold)}</td>
                  <td data-title="Notes">{adjustment.notes}</td>
                </tr>
              )
            })}
          </tbody>
        </BondTable>
      </BondSection>
    )
  } 
  return (
    <BondSection id="bond-adjustments">
      <BondHeading>{"Adjustment History"}</BondHeading>
      <p className="center">None</p>
    </BondSection>
  )
}

export default SingleBondAdjustments