import { connect } from 'react-redux'
import SiteHeader from '../elements/SiteHeader/SiteHeader'
import { fetchBondsIfNeeded } from '../redux/Bonds/bonds.actions'

const mapStateToProps = (state) => {
  return {
    user: state.user,
    bonds: state.bonds,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBondsIfNeeded: () => { dispatch(fetchBondsIfNeeded()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteHeader)