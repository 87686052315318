import React, { useEffect } from "react"
import PropTypes from "prop-types"
import Layout from "../../elements/Layout/Layout"
import Spinner from "../../elements/Spinner/Spinner"
import BondMatrix from "../../components/BondMatrix/BondMatrix"

const Bond = ({ bonds, fetchBondsIfNeeded, history }) => {
  
  useEffect(() => {
    fetchBondsIfNeeded()
  })

  return (
    <Layout>
      {bonds ?
      <>
        <h1>Transactions</h1>
        <BondMatrix bonds={bonds} history={history} />
      </>
      : <Spinner />}
    </Layout>
  )

}

Bond.propTypes = {
  history: PropTypes.object,
}

export default Bond 