import React, { Component, Fragment } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import Adjustments from './containers/Adjustments'
import Bonds from './containers/Bonds'
import Home from './containers/Home'
import LogIn from './containers/LogIn'
import NotFound from './screens/NotFound/NotFound'
import SingleBond from './screens/SingleBond/SingleBond'
import Viewer from './screens/Viewer/Viewer'

class PrivateRoute extends Component {
  render() {
    // if (this.props.token) {
    if (localStorage.getItem('token') !== null) {
      return (
        <Fragment>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/login" component={Home} />
            <Route exact path="/adjustment-notices" component={Adjustments} />
            <Route exact path="/convertible-bonds" component={Bonds} />
            <Route exact path="/convertible-bond/:id" component={SingleBond} />
            <Route exact path="/viewer" component={Viewer} />
            <Route path="/404" render={() => <NotFound />} />
            <Route path="*" render={() => <Redirect to="/404" />} />
          </Switch>
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          <Route exact path="/login" component={LogIn} />
          <Route exact path="*" render={() => <Redirect to="/login" />} />
        </Fragment>
      )
    }
  }
}

const mapStateToProps = state => {
  return {
    token: state.user.token
  }
}

export default connect(mapStateToProps)(PrivateRoute)