import styled from "styled-components"

const FormWrapper = styled.div`
  form {
    margin: 2rem auto;
    max-width: 30em;
    .form-group { 
      margin-bottom: 2rem;
      label {
        display: block;
        font-size: 12px;
        margin-bottom: 10px;
      }
      input {
        border: 1px solid #D8D8D8;
        padding: 1rem;
        width: 100%;
      }
      input:focus {
        border: 1px solid #D8D8D8;
        border-radius: 0;
      }
    }
    button {
      display: block;
      font-size: 12px;
      margin-left: auto;
      margin-right: auto;
      text-transform: none;
      width: 8rem; 
      &:hover, &:focus {
        background-color: transparent;
        color: #9C2729;
        transition: all 0.3s ease;
      }
    }
  }
`

const LargeParagraph = styled.p`
  font-size: 18px;
  text-align: center;
`

const SmallParagraph = styled.p`
  font-size: 14px;
  text-align: center;
  a { 
    color: #9C2729
    &:hover, &:focus {
      text-decoration: none;
    }
  }
`

export {
  FormWrapper,
  LargeParagraph,
  SmallParagraph
}
