import React from "react"
import {
  FooterLeft, 
  FooterRight,
  InnerWrapper,
  SiteFooter,
} from "./styles"

const Footer = () => (
  <SiteFooter>
    <InnerWrapper>
      <FooterLeft>
        <p><a href="https://conv-ex.com" target="_blank" rel="noopener noreferrer">conv-ex.com</a></p>
        <p style={{ color: "#9a9a9a" }}>
          Conv-Ex Advisors Limited is authorised and regulated by the Financial Conduct Authority.
        </p>
      </FooterLeft>

      <FooterRight>
        <p>30 Crown Place, London EC2A 4EB</p>
        <p>© ConvEx Advisors {new Date().getFullYear()}</p>        
      </FooterRight>
    </InnerWrapper>
  </SiteFooter>
)

export default Footer