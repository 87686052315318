import React from "react";
import { withRouter } from "react-router-dom";
import { useTable, useSortBy, usePagination } from "react-table";
import { returnFormatted } from "../../utilities/format";
import { makeNoticesData, getNoticesColumns } from "../../utilities/makeData";
import PDFIcon from "../../constants/images/pdf-icon.png";

function Table({ history, columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // Pagination
    page,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
    },
    useSortBy,
    usePagination
  );

  const handleViewerClick = (file) => {
    if (localStorage.getItem("viewerUrl")) {
      localStorage.removeItem("viewerUrl");
    }
    localStorage.setItem("viewerUrl", file);
    history.push("/viewer");
  };

  return (
    <div className="section-latest-notices">
      <h2>Latest Notices</h2>
      <button
        className="button-all button-first"
        onClick={() => history.push("/adjustment-notices")}
      >
        View all Notices
      </button>
      <table
        {...getTableProps()}
        className="cb-table cb-table-hover cb-table-latest"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  className={column.isSorted ? "cell-active" : null}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.Header === "File" ? null : (
                    <span className="th-span">{column.render("Header")}</span>
                  )}
                  {column.isSorted && column.isSortedDesc ? (
                    <span className="chevron top"></span>
                  ) : (
                    <span className="chevron bottom"></span>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                onClick={() => handleViewerClick(row.original.pdf_link)}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  if (cell.column.Header === "Notice Date") {
                    return (
                      <td
                        className="cell-pdf-icon"
                        data-title={cell.column.Header}
                        {...cell.getCellProps()}
                      >
                        <img src={PDFIcon} alt="" width="25" />
                        {returnFormatted(cell.value)}
                      </td>
                    );
                  } else {
                    return (
                      <td
                        data-title={cell.column.Header}
                        {...cell.getCellProps()}
                      >
                        {returnFormatted(cell.value)}
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <button
        className="button-all"
        onClick={() => history.push("/adjustment-notices")}
      >
        View all Notices
      </button>
    </div>
  );
}

const LatestNoticesMatrix = withRouter(({ history, bonds }) => {
  const columns = getNoticesColumns();
  const data = makeNoticesData(bonds);

  return <Table history={history} columns={columns} data={data} />;
});

export default LatestNoticesMatrix;
