import styled from "styled-components"

const Button = styled.button`
  border: none;
  background: none;
  color: white;
  display: block;
  font-size: 16px;
  font-weight: bold;
  margin: 2rem 0;
  padding: 0;
  transition: color 0.3s linear;
  text-align: left;
  &:hover, &:focus {
    color: #cacaca;
  }
`

const Navigation = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  background: #000;
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
  height: 100vh;
  text-align: left;
  padding: 10rem 2rem 2rem;
  position: fixed;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 100;
  @media (max-width: 576px) {
    width: 100%;
  }
  a {
    cursor: pointer;
    color: #fff;
    display: block;
    font-size: 16px;
    font-weight: bold;
    margin: 2rem 0;
    text-decoration: none;
    transition: color 0.3s linear;
    text-align: left;
    &:hover, &:focus {
      color: #cacaca;
    }
  }
  #list-bottom {
    padding-top: 2rem;
    a {
      color: #cacaca;
      font-size: 14px;
    }
  }
`

const Burger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  position: relative;
  z-index: 101;

  div {
    width: 30px;
    height: 2px;
    background: #fff;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
      opacity: ${({ open }) => open ? '0' : '1'};
      transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`

export { Burger, Button, Navigation }